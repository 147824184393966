import {
  BottomSheet,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import pluralize from "pluralize";

import { Button } from "../../../components/Button";

interface MissingFacilityRequiredDocumentsBottomSheetProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onCancel: () => void;
  missingDocuments: string[];
  workplaceName: string;
}

export function MissingFacilityRequiredDocumentsBottomSheet(
  props: MissingFacilityRequiredDocumentsBottomSheetProps
) {
  const { modalState, onConfirm, onCancel, missingDocuments, workplaceName } = props;
  const pluralizedDocument = pluralize("document", missingDocuments.length);

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            Accept Invite
          </Button>
          <Button fullWidth size="large" variant="outlined" onClick={onCancel}>
            Go Back
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={2}>
          <Title semibold variant="h4" component="h4" textAlign="center">
            Missing Required Documents
          </Title>
          <Stack sx={{ paddingTop: 4 }}>
            <Text color={(theme) => theme.palette.text.secondary} variant="body1">
              You are missing the following {pluralizedDocument} required by {workplaceName}:
            </Text>
          </Stack>
          <Stack sx={{ paddingY: 4 }}>
            <List dense>
              {missingDocuments.slice(0, 6).map((document) => (
                <ListItem key={document}>
                  <ListItemIcon iconType="document" />
                  <ListItemText primary={document} />
                </ListItem>
              ))}
            </List>
          </Stack>
          <Text paragraph>
            You can still accept this shift invite, but please make sure to upload the above{" "}
            {pluralizedDocument} as soon as you can.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
