import { Stack } from "@mui/material";
import { ShiftInviteItemProvider } from "@src/appV2/Shifts/ShiftInvites/context/shiftInviteItemContext";
import { type ShiftInvitesResponse } from "@src/appV2/Shifts/ShiftInvites/types";

import { ShiftInviteAlerts } from "./Alerts";
import { ShiftInviteCard } from "./Card";

interface ShiftInvitesListProps {
  shiftInvites: ShiftInvitesResponse["data"];
  currentDate: Date;
}

export function ShiftInvitesList(props: ShiftInvitesListProps) {
  const { shiftInvites, currentDate } = props;

  return (
    <Stack spacing={6}>
      {shiftInvites.map((request) => {
        return (
          <ShiftInviteItemProvider key={request.id}>
            <ShiftInviteCard shiftInvite={request} currentDate={currentDate} />
            <ShiftInviteAlerts shiftInvite={request} />
          </ShiftInviteItemProvider>
        );
      })}
    </Stack>
  );
}
