import { DotSeparatedList, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { useShiftInviteActions } from "@src/appV2/Shifts/ShiftInvites/api/useShiftInviteActions";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import { parseISO } from "date-fns";

import { Button } from "../../components/Button";
import { formatHoursDurationCompact } from "../../utils/formatHoursDurationCompact";
import { getShiftWorkDurationInHours } from "../../utils/getShiftWorkDuration";
import { WorkerShiftRequestCard } from "../components/RequestCard";
import { getShiftInviteExpirationText } from "./utils";

interface ShiftInviteCardProps {
  currentDate: Date;
  shiftInvite: ShiftInvite;
}

export function ShiftInviteCard(props: ShiftInviteCardProps) {
  const { currentDate, shiftInvite } = props;
  const {
    attributes: {
      shiftDetails: { name: shiftTimeSlot, start, end, agentReq, pay },
      facility: { name: workplaceName, requiresLunchBreak },
      expiresAt,
    },
    id,
  } = shiftInvite;

  const { acceptShiftInvite, declineShiftInvite, isLoadingAcceptOrDecline } =
    useShiftInviteActions();

  const parsedExpiresAt = parseISO(expiresAt);
  const inviteIsExpired = parsedExpiresAt <= currentDate;
  const shiftWorkDurationInHours = getShiftWorkDurationInHours(
    { start, end },
    requiresLunchBreak ?? false
  );

  return (
    <WorkerShiftRequestCard
      shiftTimeSlot={shiftTimeSlot as ShiftPeriod}
      shiftStart={start}
      shiftEnd={end}
      pills={[
        <Pill
          key="shift-invite-expiry"
          label={getShiftInviteExpirationText(parsedExpiresAt, currentDate)}
          color={(theme) => theme.palette.intent?.error.text}
          backgroundColor={(theme) => theme.palette.intent?.error.background}
          iconColor={(theme) => theme.palette.intent?.error.icon}
        />,
      ]}
      details={
        <>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            <DotSeparatedList>
              {[agentReq, workplaceName, formatHoursDurationCompact(shiftWorkDurationInHours)]}
            </DotSeparatedList>
          </Text>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            <DotSeparatedList>
              {[
                `${formatDollarsAsUsd(pay)} /hr`,
                `${formatDollarsAsUsd(pay * shiftWorkDurationInHours)} total`,
              ]}
            </DotSeparatedList>
          </Text>
        </>
      }
    >
      <Stack sx={{ paddingTop: 2 }}>
        <Stack spacing={4}>
          <Button
            variant="contained"
            startIconType="checkmark"
            disabled={inviteIsExpired || isLoadingAcceptOrDecline}
            onClick={async () => {
              await acceptShiftInvite({ shiftInvite });
            }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            startIconType="close"
            disabled={inviteIsExpired || isLoadingAcceptOrDecline}
            onClick={async () => {
              await declineShiftInvite({ shiftInviteId: id });
            }}
          >
            Decline
          </Button>
        </Stack>
      </Stack>
    </WorkerShiftRequestCard>
  );
}
