import { BottomSheet } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

import { Button } from "../../../components/Button";

interface OverworkingBottomSheetProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onCancel: () => void;
}

export function OverworkingBottomSheet(props: OverworkingBottomSheetProps) {
  const { modalState, onConfirm, onCancel } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            Accept Anyway
          </Button>
          <Button fullWidth size="large" variant="outlined" onClick={onCancel}>
            Go Back
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={6} alignItems="center" sx={{ py: 4 }}>
          <Title semibold variant="h4" component="h4" textAlign="center">
            Potential Overwork
          </Title>
          <Text
            color={(theme) => theme.palette.text.secondary}
            sx={{ textAlign: "center" }}
            variant="body1"
          >
            Accepting this shift invite will result in you being booked for more than 17 hours of
            consecutive work.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
