import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { SHIFT_DISCOVERY_LIST_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/redesign/ShiftDiscovery/useIsShiftDiscoveryEnabled";

import { Button } from "../../components/Button";

export function ShiftInvitesEmptyState() {
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  return (
    <Stack
      sx={{
        flex: 1,
        flexGrow: 1,
        alignItems: "center",
      }}
      justifyContent="center"
      spacing={5}
    >
      <Title semibold variant="h4" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
        You don&apos;t have any shift invites right now
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        Invites are sent from facilities directly to workers
      </Text>
      <Button
        variant="contained"
        href={
          isShiftDiscoveryEnabled
            ? `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`
            : DeprecatedGlobalAppV1Paths.OPEN_SHIFTS
        }
      >
        Browse Shifts
      </Button>
    </Stack>
  );
}
