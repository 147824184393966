import { BottomSheet } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { Button } from "../../../components/Button";

interface WeeklyHoursLimitExceededBottomSheetProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onClose: () => void;
  weeklyHoursLimit: number;
  isClipboardScoreEnabled: boolean;
}

export function WeeklyHoursLimitExceededBottomSheet(
  props: WeeklyHoursLimitExceededBottomSheetProps
) {
  const { modalState, onConfirm, onClose, weeklyHoursLimit, isClipboardScoreEnabled } = props;
  const { userId } = useDefinedWorker();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(userId);

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth size="large" variant="outlined" onClick={onClose}>
            Go Back
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={6} alignItems="center" sx={{ py: 4 }}>
          <Title semibold variant="h4" component="h4" textAlign="center">
            You have reached the weekly hours limit at this workplace
          </Title>
          <Text
            color={(theme) => theme.palette.text.secondary}
            sx={{ textAlign: "center" }}
            variant="body1"
          >
            This workplace does not allow professionals to book more than {weeklyHoursLimit} hours
            of work per week. You can cancel your other booked shifts at this workplace to accept
            this invite ({scoreType} penalties may apply).
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
