import { BottomSheet } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { Button } from "../../../components/Button";

interface ShiftBookingConflictBottomSheetProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onClose: () => void;
  conflictingShift: Shift;
  isClipboardScoreEnabled: boolean;
}

export function ShiftBookingConflictBottomSheet(props: ShiftBookingConflictBottomSheetProps) {
  const { isClipboardScoreEnabled, modalState, onConfirm, onClose, conflictingShift } = props;

  const conflictedFacilityName = conflictingShift.facility.name;
  const { userId } = useDefinedWorker();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(userId);

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth size="large" variant="outlined" onClick={onClose}>
            Go Back
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={6} alignItems="center" sx={{ py: 4 }}>
          <Title semibold variant="h4" component="h4" textAlign="center">
            You&apos;re booked for a conflicting shift
          </Title>
          <Text
            color={(theme) => theme.palette.text.secondary}
            sx={{ textAlign: "center" }}
            variant="body1"
          >
            The shift you are invited to overlaps with your booked shift at {conflictedFacilityName}
            . You can cancel your other booked shift to accept this invite ({scoreType} penalties
            may apply).
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
