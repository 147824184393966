import { getTheme } from "@clipboard-health/ui-theme";
import { Alert, ThemeProvider } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { useRef } from "react";

import { PageContent } from "../components/PageContent";
import { ShiftInvitesEmptyState } from "./EmptyState";
import { ShiftInvitesList } from "./List";
import { ShiftInvitesLoadingPageState } from "./LoadingPageState";

export function ShiftInvitesPage() {
  const {
    data: shiftInvitesData,
    refetch: refetchShiftInvites,
    isLoading,
    isError,
    isSuccess,
  } = useWorkerPendingShiftInvites();

  const currentDate = useCurrentDate(1000);

  const pendingShiftInvites = shiftInvitesData?.data ?? [];

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <StickyScrollPageHeader
          title="Shift invites"
          isLoading={isLoading}
          scrollContainerRef={scrollRef}
        />

        <PageContent ref={scrollRef}>
          <PullToRefresh
            onRefresh={async () => {
              await refetchShiftInvites();
            }}
          />
          {isLoading && <ShiftInvitesLoadingPageState />}
          {isError && <Alert severity="error">Error loading shift invites.</Alert>}
          {isSuccess &&
            (pendingShiftInvites.length > 0 ? (
              <ShiftInvitesList shiftInvites={pendingShiftInvites} currentDate={currentDate} />
            ) : (
              <ShiftInvitesEmptyState />
            ))}
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
