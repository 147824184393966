// eslint-disable-next-line no-restricted-imports
import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function ShiftInvitesLoadingPageState() {
  return (
    <Stack spacing={6} aria-label="Loading shift invites">
      {Array.from({ length: 3 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={index}>
          <CardContent sx={{ padding: 6 }}>
            <Stack spacing={6}>
              {/* Shift time slot */}
              <Stack direction="row" spacing={2}>
                <Skeleton variant="rounded" width={120} height={24} />
                <Skeleton variant="rounded" width={80} height={24} />
              </Stack>

              {/* Facility and shift details */}
              <Stack spacing={2}>
                <Skeleton variant="text" width={240} />
                <Skeleton variant="text" width={180} />
              </Stack>

              {/* Pay details */}
              <Stack spacing={2}>
                <Skeleton variant="text" width={120} />
                <Skeleton variant="text" width={160} />
              </Stack>

              {/* Action buttons */}
              <Stack spacing={4}>
                <Skeleton variant="rectangular" height={40} />
                <Skeleton variant="rectangular" height={40} />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}
