import { differenceInMinutes, differenceInSeconds, isBefore } from "date-fns";
import pluralize from "pluralize";

export function getShiftInviteExpirationText(expirationDate: Date, currentTime: Date) {
  if (isBefore(expirationDate, currentTime)) {
    return "Expired";
  }

  const minutesUntilExpiration = differenceInMinutes(expirationDate, currentTime);
  const hoursUntilExpiration = Math.floor(minutesUntilExpiration / 60);
  const daysUntilExpiration = Math.floor(hoursUntilExpiration / 24);
  const remainingHours = hoursUntilExpiration % 24;
  const remainingMinutes = minutesUntilExpiration % 60;
  const secondsUntilExpiration = differenceInSeconds(expirationDate, currentTime);

  if (daysUntilExpiration > 0) {
    return `Expires in ${daysUntilExpiration} ${pluralize("day", daysUntilExpiration)}${
      remainingHours > 0 ? ` and ${remainingHours} ${pluralize("hour", remainingHours)}` : ""
    }`;
  }

  if (minutesUntilExpiration > 60) {
    return `Expires in ${hoursUntilExpiration} ${pluralize("hour", hoursUntilExpiration)}${
      remainingMinutes > 0 ? ` and ${remainingMinutes} min` : ""
    }`;
  }

  if (minutesUntilExpiration > 1) {
    return `Expires in ${minutesUntilExpiration} ${pluralize("minute", minutesUntilExpiration)}`;
  }

  return `Expires in ${secondsUntilExpiration} sec`;
}
