import { BottomSheet } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";

import { Button } from "../../../components/Button";

interface ShiftInviteConflictBottomSheetProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onCancel: () => void;
  conflictingInvites: ShiftInvite[];
}

export function ShiftInviteConflictBottomSheet(props: ShiftInviteConflictBottomSheetProps) {
  const { modalState, onConfirm, onCancel, conflictingInvites } = props;
  const facilityNames = conflictingInvites
    .map((invite) => invite.attributes.facility.name)
    .join(", ");

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button fullWidth size="large" variant="contained" onClick={onConfirm}>
            Accept and Decline Others
          </Button>
          <Button fullWidth size="large" variant="outlined" onClick={onCancel}>
            Go Back
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={6} alignItems="center" sx={{ py: 4 }}>
          <Title semibold variant="h4" component="h4" textAlign="center">
            You have conflicting invites
          </Title>
          <Text
            color={(theme) => theme.palette.text.secondary}
            sx={{ textAlign: "center" }}
            variant="body1"
          >
            You have pending invites at {facilityNames} that conflict with this shift. Accepting
            this invite will automatically decline those invites.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
