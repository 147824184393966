import { isDefined } from "@clipboard-health/util-ts";
import { RootPaths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import { useShiftInviteActions } from "@src/appV2/Shifts/ShiftInvites/api/useShiftInviteActions";
import { useShiftInviteItemContext } from "@src/appV2/Shifts/ShiftInvites/context/shiftInviteItemContext";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import { useHistory } from "react-router-dom";

import { LocationPermissionsBottomSheet } from "../../Shift/Bookability/LocationPermissionsBottomSheet";
import { MissingCoreRequiredDocumentsBottomSheet } from "./dialogs/MissingCoreRequiredDocumentsBottomSheet";
import { MissingFacilityRequiredDocumentsBottomSheet } from "./dialogs/MissingFacilityRequiredDocumentsBottomSheet";
import { OverworkingBottomSheet } from "./dialogs/OverworkingBottomSheet";
import { ShiftBookingConflictBottomSheet } from "./dialogs/ShiftBookingConflictBottomSheet";
import { ShiftInviteConflictBottomSheet } from "./dialogs/ShiftInviteConflictBottomSheet";
import { WeeklyHoursLimitExceededBottomSheet } from "./dialogs/WeeklyHoursLimitExceededBottomSheet";

interface ShiftInviteAlertsProps {
  shiftInvite: ShiftInvite;
}
export function ShiftInviteAlerts(props: ShiftInviteAlertsProps) {
  const { shiftInvite } = props;
  const history = useHistory();
  const { acceptShiftInvite } = useShiftInviteActions();
  const {
    conflictingShift,
    conflictingInvites,
    conflictingShiftDialogState,
    conflictingInviteDialogState,
    overworkingShiftDialogState,
    locationPermissionsModalState,
    missingFacilityRequiredDocumentsDialogState,
    missingCoreRequiredDocumentsDialogState,
    missingDocuments,
    weeklyHoursLimitExceededDialogState,
    weeklyHoursLimit,
    resetWarnings,
    shouldDeclineConflictingInvites,
    skipFacilityRequiredDocumentCheck,
    isOverworkingShiftsAllowed,
    setShouldDeclineConflictingInvites,
    setSkipFacilityRequiredDocumentCheck,
    setIsOverworkingShiftsAllowed,
  } = useShiftInviteItemContext();

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  return (
    <>
      {isDefined(conflictingShift) && (
        <ShiftBookingConflictBottomSheet
          isClipboardScoreEnabled={isClipboardScoreEnabled}
          modalState={conflictingShiftDialogState}
          conflictingShift={conflictingShift}
          onClose={() => {
            resetWarnings();
            conflictingShiftDialogState.closeModal();
          }}
          onConfirm={() => {
            resetWarnings();
            conflictingShiftDialogState.closeModal();
            history.push(`${RootPaths.APP_V2_HOME}/${MY_SHIFTS_PATH}`);
          }}
        />
      )}

      {conflictingInvites?.length > 0 && (
        <ShiftInviteConflictBottomSheet
          modalState={conflictingInviteDialogState}
          conflictingInvites={conflictingInvites}
          onCancel={() => {
            resetWarnings();
            conflictingInviteDialogState.closeModal();
          }}
          onConfirm={async () => {
            conflictingInviteDialogState.closeModal();
            setShouldDeclineConflictingInvites(true);
            await acceptShiftInvite({
              shiftInvite,
              isOverworkingShiftsAllowed,
              skipFacilityRequiredDocumentCheck,
              shouldDeclineConflictingInvites: true,
            });
          }}
        />
      )}

      <OverworkingBottomSheet
        modalState={overworkingShiftDialogState}
        onCancel={() => {
          resetWarnings();
          overworkingShiftDialogState.closeModal();
        }}
        onConfirm={async () => {
          overworkingShiftDialogState.closeModal();
          setIsOverworkingShiftsAllowed(true);
          await acceptShiftInvite({
            shiftInvite,
            isOverworkingShiftsAllowed: true,
            shouldDeclineConflictingInvites,
            skipFacilityRequiredDocumentCheck,
          });
          logEvent(APP_V2_APP_EVENTS.ACCEPT_OVERWORKING_SHIFT_INVITE, {
            shiftInvite,
          });
        }}
      />

      <MissingFacilityRequiredDocumentsBottomSheet
        modalState={missingFacilityRequiredDocumentsDialogState}
        missingDocuments={missingDocuments}
        workplaceName={shiftInvite.attributes.facility.name}
        onCancel={() => {
          resetWarnings();
          missingFacilityRequiredDocumentsDialogState.closeModal();
        }}
        onConfirm={async () => {
          missingFacilityRequiredDocumentsDialogState.closeModal();
          setSkipFacilityRequiredDocumentCheck(true);
          await acceptShiftInvite({
            shiftInvite,
            shouldDeclineConflictingInvites,
            isOverworkingShiftsAllowed,
            skipFacilityRequiredDocumentCheck: true,
          });
        }}
      />

      <MissingCoreRequiredDocumentsBottomSheet
        modalState={missingCoreRequiredDocumentsDialogState}
        missingDocuments={missingDocuments}
        onCancel={() => {
          resetWarnings();
          missingCoreRequiredDocumentsDialogState.closeModal();
        }}
      />

      {weeklyHoursLimit > 0 && (
        <WeeklyHoursLimitExceededBottomSheet
          isClipboardScoreEnabled={isClipboardScoreEnabled}
          modalState={weeklyHoursLimitExceededDialogState}
          weeklyHoursLimit={weeklyHoursLimit}
          onConfirm={() => {
            resetWarnings();
            weeklyHoursLimitExceededDialogState.closeModal();
            history.push(`${RootPaths.APP_V2_HOME}/${MY_SHIFTS_PATH}`);
          }}
          onClose={() => {
            resetWarnings();
            weeklyHoursLimitExceededDialogState.closeModal();
          }}
        />
      )}

      <LocationPermissionsBottomSheet modalState={locationPermissionsModalState} />
    </>
  );
}
